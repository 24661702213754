import 'dayjs/locale/es'

import {i18n} from '@lingui/core'
import {I18nProvider as LinguiI18nProvider} from '@lingui/react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import {useGetAuthUserQuery} from 'generated/graphql'
import {messages as enUSMessages} from 'locale/en-us/messages'
import {messages as esARMessages} from 'locale/es-ar/messages'
import {messages as ptBRMessages} from 'locale/pt-br/messages'
import {en, es} from 'make-plural/plurals'
import numbro from 'numbro'
import {createContext, FC, useContext, useEffect, useState} from 'react'
import * as settings from 'settings'

// Translations

i18n.loadLocaleData({'en-us': {plurals: en}, 'es-ar': {plurals: es}, 'pt-br': {plurals: es}})
i18n.load({'en-us': enUSMessages as any, 'es-ar': esARMessages as any, 'pt-br': ptBRMessages as any}) // TODO: Remove 'as any' when types are fixed in Lingui v3.

interface I18nProviderProps {
    initialLanguage: string
}

interface I18nContextProps {
    setLanguage(lang: string): void
    currentLang: string
}

const I18nContext = createContext<I18nContextProps>({
    setLanguage: () => {},
    currentLang: settings.LANGUAGE_CODE,
})

export const useI18nContext = () => useContext(I18nContext)

export const I18nProvider: FC<I18nProviderProps> = ({initialLanguage, children}) => {
    const [language, setLanguage] = useState(initialLanguage)
    const {data} = useGetAuthUserQuery()

    useEffect(() => {
        i18n.activate(language)
        dayjs.locale(language.split('-')[0])
        numbro.setLanguage(language)
        setLanguage(language)
    }, [language])

    useEffect(() => {
        if (data?.authUser?.language) setLanguage(data.authUser.language)
    }, [data?.authUser?.language])

    useEffect(() => {
        setLanguage(initialLanguage)
    }, [initialLanguage])

    if (!i18n.locale) return null

    return (
        <LinguiI18nProvider i18n={i18n} key={language}>
            <I18nContext.Provider
                value={{
                    setLanguage: lang => {
                        setLanguage(lang)
                        window.localStorage.setItem('lang', lang)
                    },
                    currentLang: settings.LANGUAGES.find(lang => lang.code === language)?.name as string,
                }}>
                {children}
            </I18nContext.Provider>
        </LinguiI18nProvider>
    )
}

// Date & Time

dayjs.locale(settings.LANGUAGE_CODE.split('-')[0])
dayjs.extend(utc)
dayjs.extend(duration)
dayjs.extend(relativeTime)

export const formatTimestamp = (date: string | undefined, format: string) => {
    return dayjs(date).utcOffset(settings.TIMEZONE).format(format)
}

export const formatDate = (date?: string, format = settings.DATE_FORMAT) => formatTimestamp(date, format)
export const formatDateTime = (date?: string) => formatTimestamp(date, settings.DATETIME_FORMAT)
export const humanize = (date?: string, withSuffix = false) => dayjs(date).fromNow(withSuffix)
// Numbers

settings.LANGUAGES.forEach(({code}) => {
    // Country code must be uppercase to match numbro's filenames.
    const [language, country] = code.split('-')
    code = `${language}-${country.toUpperCase()}`

    // Skip numbro's default language.
    if (language === 'en') return

    numbro.registerLanguage(require(`numbro/languages/${code}`))
})

numbro.setLanguage(settings.LANGUAGE_CODE)
numbro.setDefaults({
    mantissa: settings.MAX_DECIMALS,
    thousandSeparated: true,
})

export const formatNumber = (number: number): string => {
    return numbro(number).format({
        trimMantissa: true,
    })
}

export const formatPercentage = (number: number): string => {
    return numbro(number).format({
        output: 'percent',
        trimMantissa: true,
        spaceSeparated: true,
    })
}

export const formatCurrency = (number: number, currency?: string, options: {decimals?: number} = {}): string => {
    const result = numbro(number).format({
        output: 'currency',
        currencySymbol: currency || 'USD',
        currencyPosition: 'postfix',
        spaceSeparated: true,
        mantissa: options.decimals === undefined ? settings.MAX_DECIMALS : options.decimals,
    })
    // Use a non-breaking space so currency code doesn't wrap to another line.
    // https://en.wikipedia.org/wiki/Non-breaking_space
    return result.replace(' ', '\u00A0')
}

export const formatAbbreviatedCurrency = (number: number, currency?: string): string => {
    let abbreviated = ''
    if (number < 1000) abbreviated = `${number}`
    else if (number < 1000000) abbreviated = `${Math.round(number / 1000)}k`
    else abbreviated = `${Number((number / 1000000).toFixed(1))}M`
    return `${abbreviated}\u00A0${currency || 'USD'}`
}
